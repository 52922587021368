import React from 'react'
import { prepareParagraph, titleToId } from '../../../../utils/format'
import styles from './style.module.sass'

export default ({ ingredients }) => {
  return (
    <div className={styles.ingredients}>
      <div className={styles.anchor} id={titleToId(ingredients.titleNav)} data-js="scroll-section" />
      <h2 className={styles.title}>{ingredients.title}</h2>
      <p className={styles.info}>{ingredients.info}</p>

      <ul className={styles.list}>
        {ingredients.items &&
          ingredients.items.map((item, index) => (
            <li className={styles.item} key={item._key}>
              <p className={styles.top}>
                <span>{item.title}</span>
                <span>{item.dosage}</span>
              </p>
              <p className={styles.text}>{prepareParagraph(item.text)}</p>
            </li>
          ))}
      </ul>
    </div>
  )
}
